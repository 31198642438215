<div
  *ngIf="reports && currentReport" class="reports-page">
  <div class="reports-page__nav">
    <reports-nav
      [reports]="reports"
      [defaultItem]="currentReport"
      (selected)="selectReport($event)">
    </reports-nav>
  </div>

  <div class="reports-page__content">
    <app-power-bi
      [report]="currentReport"
      [pageNavigationVisible]="isMultiPageReport">
    </app-power-bi>
  </div>
</div>

