import { Component, Input } from '@angular/core';

import { MenuItem } from '../../models/menu-item.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input()
    public menuItems: MenuItem[];
}
