import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Report } from '@app/shared/features/reports/models/report.model';
import { ReportsItem } from '@app/shared/features/reports/types/reports-item.type';
import { ReportGroupNodeValue } from './models/report-group-node-value.model';
import { ReportTree } from './models/report-tree.model';

@Component({
    selector: 'reports-nav',
    templateUrl: './reports-nav.component.html',
    styleUrls: ['./reports-nav.component.scss']
})
export class ReportsNavComponent {
    public selectedReport: Report = null;
    public reportsTree: ReportTree;

  @Input()
    public set reports(reports: ReportsItem[]) {
        this.reportsTree = new ReportTree(reports || []);
    }

  @Input()
  public set defaultItem(value: Report) {
      this.selectedReport = value;
  }

  @Output()
  private selected = new EventEmitter<any>();

  public onSelect(value: Report): void {
      if (!this.isReportNode(value)) {
          return;
      }
      this.selectedReport = value;
      this.selected.emit(value);
  }

  public isActive(value: Report): boolean {
      if (value.page) {
          return value.page === this.selectedReport.page;
      }

      return value.name === this.selectedReport.name;

  }

  public isReportNode(value: Report | ReportGroupNodeValue): boolean {
      return value instanceof Report;
  }

  public isSeparated(value: Report | ReportGroupNodeValue): boolean {
      return value?.title?.toLocaleLowerCase() === 'qep only';
  }
}
