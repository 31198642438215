import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { MainLayoutComponent } from './main-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { UserComponent } from './components/user/user.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        MenuComponent,
        HeaderComponent,
        MainLayoutComponent,
        UserComponent,
        ProfilePictureComponent
    ],
    imports: [
        SharedModule,
        AppRoutingModule,
        RouterModule
    ],
    exports: [
        MainLayoutComponent
    ]
})
export class MainLayoutModule { }
