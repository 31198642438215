import { Inject, Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Location } from '@angular/common';
import {
    IPublicClientApplication,
    AccountInfo,
    EndSessionRequest,
    AuthorizationUrlRequest,
    AuthenticationResult,
    PopupRequest,
    RedirectRequest,
    SilentRequest,
    EventMessage,
    EventType
} from '@azure/msal-browser';

import { CurrentUserStateService } from '../services/current-user-state.service';
import { MSAL_INSTANCE } from './constants';

interface IMsalService {
  acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult>;
  acquireTokenRedirect(request: RedirectRequest): Observable<void>;
  acquireTokenSilent(
    silentRequest: SilentRequest
  ): Observable<AuthenticationResult>;
  getAccountByUsername(userName: string): AccountInfo | null;
  getAllAccounts(): AccountInfo[];
  handleRedirectObservable(): Observable<AuthenticationResult | null>;
  loginPopup(request?: PopupRequest): Observable<AuthenticationResult>;
  loginRedirect(request?: RedirectRequest): Observable<void>;
  logout(logoutRequest?: EndSessionRequest): Observable<void>;
  ssoSilent(request: AuthorizationUrlRequest): Observable<AuthenticationResult>;
}

@Injectable()
export class MsalService implements IMsalService {
    private redirectHash: string;

    constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private location: Location,
    private currentUserStateService: CurrentUserStateService
    ) {
    // Cache the code hash before Angular router clears it
        const hash = this.location.path(true).split('#').pop();

        if (hash) {
            this.redirectHash = `#${hash}`;
        }

        this.handleLogoutEvent();
    }

    public acquireTokenPopup(
        request: PopupRequest
    ): Observable<AuthenticationResult> {
        return from(this.msalInstance.acquireTokenPopup(request));
    }

    public acquireTokenRedirect(request: RedirectRequest): Observable<void> {
        return from(this.msalInstance.acquireTokenRedirect(request));
    }

    public acquireTokenSilent(
        silentRequest: SilentRequest
    ): Observable<AuthenticationResult> {
        return from(this.msalInstance.acquireTokenSilent(silentRequest));
    }

    public getAccountByUsername(userName: string): AccountInfo {
        return this.msalInstance.getAccountByUsername(userName);
    }

    public getAllAccounts(): AccountInfo[] {
        return this.msalInstance.getAllAccounts();
    }

    public isAuthorized(): boolean {
        return Boolean(this.getAllAccounts()?.length);
    }

    public handleRedirectObservable(): Observable<AuthenticationResult> {
        return from(this.msalInstance.handleRedirectPromise(this.redirectHash));
    }

    public loginPopup(
        request?: PopupRequest
    ): Observable<AuthenticationResult> {
        return from(this.msalInstance.loginPopup(request));
    }

    public loginRedirect(request?: RedirectRequest): Observable<void> {
        return from(this.msalInstance.loginRedirect(request));
    }

    public logout(logoutRequest?: EndSessionRequest): Observable<void> {
        return from(this.msalInstance.logout(logoutRequest));
    }

    public ssoSilent(
        request: AuthorizationUrlRequest
    ): Observable<AuthenticationResult> {
        return from(this.msalInstance.ssoSilent(request));
    }

    private handleLogoutEvent(): void {
        this.msalInstance.addEventCallback((message: EventMessage) => {
            if (message.eventType === EventType.LOGOUT_SUCCESS) {
                this.currentUserStateService.value = null;
            }
        });
    }
}
