import { Injectable } from '@angular/core';
import { BaseStateService } from '@app/core/services/base-state.service';
import { of } from 'rxjs';

import { UserRole } from '@common/enums/UserRole';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserStateService extends BaseStateService<User> {
    public hasRole(role: UserRole): boolean {
        const roles = this.value.roles || [];

        return role in roles;
    }

    public hasOneOfRoles(roles: UserRole[]): boolean {
        const userRoles = this.value.roles;

        for (const role of roles) {
            if (userRoles.includes(role)) {
                return true;
            }
        }

        return false;
    }
}
