import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef
} from '@angular/core';

import { sizeCSSClasses } from './data';
import { Size } from './enums';
import { Column } from './interfaces';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T> {
  @Input()
    public size: Size = null;

  @Input()
  public list: T[] = [];

  @Input()
  public columns: Column[] = [];

  @Input()
  public defaultSortField = '';

  @Output()
  public rowSelected: EventEmitter<T> = new EventEmitter<T>();

  @ContentChild('cell', { static: false })
  public cellTemplateRef: TemplateRef<any>;

  public get sizeCSSClass(): string {
      return sizeCSSClasses[this.size];
  }

  public getSortableColumn({ sortable, field }: Column): string {
      return sortable ? field : '';
  }

  public onSelectRow(value: T): void {
      this.rowSelected.emit(value);
  }
}
