import { Directive, Injector, OnInit } from '@angular/core';

import { MsalService } from '@app/core/auth/msal/msal.service';
import { BaseToggleDirective } from './base-toggle.directive';

@Directive({
    selector: '[ifAuthorized]'
})
export class IfAuthorizedDirective extends BaseToggleDirective implements OnInit {
    constructor(injector: Injector, private authService: MsalService) {
        super(injector);
    }

    protected isElementNeeded(): boolean {
        return this.authService.isAuthorized();
    }
}
