import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[clickDocument]'
})
export class ClickDocumentDirective {
  @Output()
    public documentClicked: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('document:click')
  public onMouseEnter(): void {
      this.documentClicked.emit();
  }
}
