import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { concatMap, catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { InteractionType } from '@azure/msal-browser';

import { CurrentUserStateService } from '@app/core/auth/services/current-user-state.service';
import { AppRoute } from '@app/core/enums/app-route.enum';
import { RoleRoutesService } from '../services/role-routes.service';
import { MsalService } from './msal.service';
import { MsalGuardConfiguration } from './msal.guard.config';
import { MSAL_GUARD_CONFIG } from './constants';
import { UserRole } from '@common/enums/UserRole';
import { UsersService } from '../services/users.service';

@Injectable()
export class MsalGuard implements CanActivate {
    constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private location: Location,
    private router: Router,
    private usersService: UsersService,
    private currentUserStateService: CurrentUserStateService,
    private roleRoutesService: RoleRoutesService
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        return this.authService.handleRedirectObservable().pipe(
            concatMap(() => {
                if (!this.authService.getAllAccounts().length) {
                    return this.loginInteractively(state.url);
                }

                return this.isRouteAvailableForCurrentUser(route);
            }),
            // eslint-disable-next-line no-console
            catchError((err: any) => {
                console.log(err);

                return throwError(err);
            })
        );
    }

    /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
    public getDestinationUrl(path: string): string {
    // Absolute base url for the application (default to origin if base element not present)
        const baseElements = document.getElementsByTagName('base');
        const baseUrl = this.location.normalize(
            baseElements.length ? baseElements[0].href : window.location.origin
        );

        // Path of page (including hash, if using hash routing)
        const pathUrl = this.location.prepareExternalUrl(path);

        // Hash location strategy
        if (pathUrl.startsWith('#')) {
            return `${baseUrl}/${pathUrl}`;
        }

        // If using path location strategy, pathUrl will include the relative portion of the base path
        // (e.g. /base/page).
        // Since baseUrl also includes /base, can just concatentate baseUrl + path
        return `${baseUrl}${path}`;
    }

    private loginInteractively(url: string): Observable<boolean> {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            return this.authService
                .loginPopup({ ...this.msalGuardConfig.authRequest })
                .pipe(
                    map(() => true),
                    catchError(() => of(false))
                );
        }

        const redirectStartPage = this.getDestinationUrl(url);
        this.authService.loginRedirect({
            redirectStartPage,
            scopes: [],
            ...this.msalGuardConfig.authRequest
        });

        return of(false);
    }

    private isRouteAvailableForCurrentUser(route: ActivatedRouteSnapshot): Observable<boolean> {
        const currentUser = this.currentUserStateService.value;

        if (currentUser) {
            for (const role of currentUser.roles) {
                if ( this.isRouteAvailableForUserRole(route.url[0].path, role)) {
                    return of(true);
                }
            }

            return of(false);
        }

        // the current user must be retrieved from the server after logging in
        // but we can not subscribe on success login event in case with redirect auth flow
        // so if user was not logged in previously
        // and his data is not retrieved and saved in CurrentUserStateService
        // server request for current user is sended here

        return this.usersService.getMe()
            .pipe(
                mergeMap((user) => {
                    if (!user || !user.roles) {
                        return this.redirect();
                    }
                    let isAccessed ;
                    for (const role of user.roles) {
                        this.isRouteAvailableForUserRole(route.url[0].path, role).subscribe(res =>{
                            isAccessed = res;
                        })
                        if(isAccessed){
                            return of(true);
                        }
                    }
                    return this.redirect();

                }),
                catchError(() => this.redirect())
            );
    }

    private isRouteAvailableForUserRole(route: string, role: UserRole): Observable<boolean> {
        return this.roleRoutesService.isRouteAvailableForUserRole(route, role)
            ? of(true)
            : of(false);
    }

    private redirect(): Observable<boolean> {
        this.router.navigateByUrl(`/${AppRoute.Unauthorized}`);

        return of(false);
    }
}
