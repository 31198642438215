import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Report } from '@app/shared/features/reports/models/report.model';
import { ReportsItem } from '../../types/reports-item.type';

@Component({
    selector: 'app-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent {
  @Input()
    public currentReport: Report;

  @Input()
  public reports: ReportsItem[] = null;

  @Input()
  public isMultiPageReport = false;

  @Output()
  private reportSelected: EventEmitter<any> = new EventEmitter<any>();

  public selectReport(report: Report): void {
      this.reportSelected.emit(report);
  }
}
