<p-table
    [columns]="columns"
    [value]="list"
    [styleClass]="sizeCSSClass"
    [sortField]="defaultSortField">
    <ng-template
        pTemplate="header"
        let-columns>
        <tr>
            <ng-container
                *ngFor="let col of columns">
                <th
                    [ngClass]="col.headerStyleClasses"
                    [pSortableColumn]="getSortableColumn(col)">
                    {{col.header}}
                </th>
            </ng-container>
        </tr>
    </ng-template>

    <ng-template
        pTemplate="body"
        let-rowData
        let-columns="columns">
        <tr
            (click)="onSelectRow(rowData)">
            <td
                *ngFor="let col of columns"
                [ngClass]="col.cellStyleClasses">
                <ng-container
                    *ngIf="cellTemplateRef && rowData[col.field]"
                    [ngTemplateOutlet]="cellTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:{
                      column: col.header,
                      value: rowData[col.field],
                      rowData: rowData
                    }}">
                </ng-container>
            </td>
        </tr>
    </ng-template>

</p-table>
