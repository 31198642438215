import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IEmbedConfiguration } from 'powerbi-client';
import { TokenType, LayoutType } from 'powerbi-models';

import { ScreenSizeService } from '@app/core/services/screen-size.service';
import { IPowerBiReport } from '../models/power-bi-report.model';
import { PowerBiApiService } from './power-bi-api.service';
import { PowerBiFilterData } from '../models/power-bi-filter-data.model';
import { Report } from '@app/shared/features/reports/models/report.model';

@Injectable({
    providedIn: 'root'
})
export class PowerBiConfigBuilder {
    private config: IEmbedConfiguration;

    constructor(
    private powerBiApiService: PowerBiApiService,
    private screenSizeService: ScreenSizeService
    ) {
        this.reset();
    }

    public setReportBaseInfo(reportStage: string, report: Report): Observable<IPowerBiReport> {
        return this.getReportData(reportStage, report)
            .pipe(
                tap((powerBIReport: IPowerBiReport) => {
                    this.config.accessToken = powerBIReport.embedToken;
                    this.config.embedUrl = powerBIReport.embedUrl + (report.page ? '&pageName=' + report.page : '');
                    this.config.id = powerBIReport.id;
                })
            );
    }

    public setFilters(filtersData: PowerBiFilterData[]): void {
        this.config.filters = (filtersData || []).map(({ value, table, column }) => (
            {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                    table,
                    column
                },
                operator: 'In',
                filterType: 1,
                values: [value]
            }
        ));
    }

    public setPageNavigation(pageNavigationVisible: boolean): void {
        this.config.settings.panes = {
            pageNavigation: { visible: pageNavigationVisible }
        };
    }

    public reset(): void {
        this.config = {
            type: 'report',
            tokenType: TokenType.Embed,
            settings: {
                layoutType: this.screenSizeService.isScreenWidthMedium()
                    ? LayoutType.MobilePortrait
                    : LayoutType.Master
            }
        };
    }

    public getConfig(): IEmbedConfiguration {
        const result = this.config;
        this.reset();

        return result;
    }

    private getReportData(reportStage: string, report: Report): Observable<IPowerBiReport> {
        return this.powerBiApiService.get(reportStage, report);
    }
}
