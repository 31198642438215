import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from '../models/user.model';
import { CurrentUserStateService } from './current-user-state.service';
import { UsersApiService } from './users-api.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
    private readonly usersApiService: UsersApiService,
    private readonly currentUserStateService: CurrentUserStateService
    ) {}

    public getMe(): Observable<User> {
        return this.usersApiService.getMe()
            .pipe(
                tap((user: User) => {
                    this.currentUserStateService.value = user;
                })
            );
    }
}
