import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export interface AppLetContext {
  [p: string]: any;
  $implicit: any;
}

@Directive({
    selector: '[appLet]'
})
export class LetDirective implements OnInit {
    public context: AppLetContext = {
        $implicit: null
    };

  @Input()
    public set appLetOf(value: any) {
        this.context.$implicit = value;
    }

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  public ngOnInit(): void {
      this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
