import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { UntilDestroy } from '@ngneat/until-destroy';

import { MsalService } from '@app/core/auth/msal/msal.service';
import { CurrentUserStateService } from '@app/core/auth/services/current-user-state.service';
import { UserRole } from '@common/enums/UserRole';
import { environment } from 'src/environments/environment';

// user component dropdown menu on the Q360 header bar
@UntilDestroy()
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public me: string;
    public userMenuItems: MenuItem[] = [];
    public isPreviewButtonAvailable: boolean;

    constructor(
    private authService: MsalService,
    private readonly currentUserStateService: CurrentUserStateService,
    private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.defineMenuItems();
        this.me = this.authService.getAllAccounts()[0]?.name;
    }

    public onLogoutButtonClick(): Promise<void> {
        localStorage.clear();
        return this.authService.logout().toPromise();
    }

    private defineMenuItems(): void {
        this.userMenuItems = [
            {
               label: 'User Profile',
               command: () => this.router.navigate(['/user'])
            },
            {
                label: 'Log out',
                command: () => {
                    this.onLogoutButtonClick();
                }
            }
        ];
    }
    public environment(): any {
        return environment ;
    }
}
