<p-toolbar>
    <ng-template pTemplate="left">
        <button pButton pRipple label="" icon="pi pi-plus" class="p-button-text mr-2" (click)="openNew()"></button>
        <button pButton pRipple [label]="" icon="pi pi-trash" class=" p-button-text" (click)="deleteSelectedProducts()" [attr.disabled]="!selectedProducts || !selectedProducts.length"></button>
    </ng-template>

    <ng-template pTemplate="right">
        <button pButton  icon="pi pi-upload" class=" p-button-text"></button>
    </ng-template>
</p-toolbar>  
