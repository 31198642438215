import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input()
    public title = '';

  @Input()
  public date = '';

  @Input()
  public isCloseBtnNeeded = false;

  @Input()
  // eslint-disable-next-line no-empty-function
  public closeBtnHandler: () => any = () => {};
}
