import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DropdownItem } from './dropdown-item.model';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input()
    public value: string;

  @Input()
  public items: DropdownItem[];

  @Output()
  public changed = new EventEmitter<DropdownItem>();

  public currentItemId: string;

  public ngOnInit(): void {
      this.currentItemId = this.value;
  }

  public navigateTo(id: string): void {
      this.currentItemId = id;
      this.onChanged(this.findItem(this.currentItemId));
  }

  public navigateToNext(): void {
      const index = this.findItemIndex(this.currentItemId);

      if (index < this.items.length - 1) {
          this.navigateTo(this.items[index + 1].id);
      } else {
          this.navigateTo(this.items[0].id);
      }
  }

  public navigateToPrevious(): void {
      const index = this.findItemIndex(this.currentItemId);

      if (index > 0) {
          this.navigateTo(this.items[index - 1].id);
      } else {
          this.navigateTo(this.items[this.items.length - 1].id);
      }
  }

  private onChanged(dropdownItem: DropdownItem): void {
      this.changed.emit(dropdownItem);
  }

  private findItemIndex(dropdownItemId: string): number {
      return this.items.findIndex((item) => item.id === dropdownItemId);
  }

  private findItem(dropdownItemId: string): DropdownItem {
      const index = this.findItemIndex(dropdownItemId);

      return this.items[index];
  }
}
