import { Component, ElementRef, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { NgxPowerBiService } from 'ngx-powerbi';
import { IEmbedConfiguration } from 'powerbi-client';
import { map, tap } from 'rxjs/operators';

import { SelectedReportName } from '@app/services/report-name.service';
import { PowerBiFilterData } from './models/power-bi-filter-data.model';
import { PowerBiConfigBuilder } from './services/power-bi-config.builder';
import { Report } from '@app/shared/features/reports/models/report.model';

@Component({
    selector: 'app-power-bi',
    templateUrl: './power-bi.component.html',
    styleUrls: ['./power-bi.component.scss']
})
export class PowerBiComponent implements OnChanges {
  @Input()
    public report: Report;

  @Input()
  public pageNavigationVisible = false;

  @Input()
  public filtersData: PowerBiFilterData[];

  private reportStage: string;

  @Input()
  private isPreview: boolean;

  @ViewChild('pbContainer', { static: true })
  private pbContainer: ElementRef;

  constructor(
    private powerBiConfigBuilder: PowerBiConfigBuilder,
    private ngxPowerBiService: NgxPowerBiService,
    private selectedReportName: SelectedReportName
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
      const pbiContainer = this.pbContainer.nativeElement;
      this.ngxPowerBiService.reset(pbiContainer);

      const { report, isPreview } = changes;

      if (report) {
          this.selectedReportName.reportName = report.currentValue.name;
      }

      this.reportStage = this.isPreview ? 'preview' : 'approved';

      if (report && report.currentValue) {
          this.load(this.reportStage, report.currentValue);
          // reloads current report when preview is clicked
      } else if (typeof isPreview.currentValue === 'boolean') {
          this.load(this.reportStage, this.report);
      }
  }

  private load(reportStage: string, report: Report): void {
      this.powerBiConfigBuilder
          .setReportBaseInfo(reportStage, report)
          .pipe(
              tap(() => {
                  this.powerBiConfigBuilder.setFilters(this.filtersData);
              }),
              tap(() => {
                  this.powerBiConfigBuilder.setPageNavigation(this.pageNavigationVisible);
              }),
              map(() => this.powerBiConfigBuilder.getConfig())
          )
          .subscribe(
              (config: IEmbedConfiguration) => {
                  this.render(config);
              }
          );
  }

  private render(reportConfiguration: IEmbedConfiguration): void {
      const pbiContainer = this.pbContainer.nativeElement;

      this.ngxPowerBiService.reset(pbiContainer);
      this.ngxPowerBiService.embed(pbiContainer, reportConfiguration);
  }
}
