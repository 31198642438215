import { ReportsHelper } from '@app/shared/features/reports/helpers/reports.helper';
import { ReportsItem } from '@app/shared/features/reports/types/reports-item.type';
import { Report } from '@app/shared/features/reports/models/report.model';
import { ReportGroup } from '@app/shared/features/reports/models/report-group.model';
import { ReportGroupNodeValue } from './report-group-node-value.model';

export class ReportTreeNode {
    public children: ReportTreeNode[] = [];

    constructor(
    public value: any = null,
    public parent: ReportTreeNode = null,
    children: ReportsItem[] = []
    ) {
        for (const child of children) {
            let childNodeValue;

            if (ReportsHelper.isReportGroup(child)) {
                childNodeValue = new ReportGroupNodeValue(child.title);
            } else {
                if ((child as Report).page) {
                    childNodeValue = new Report(child.title, (child as Report).name, (child as Report).page);
                } else {
                    childNodeValue = new Report(child.title, (child as Report).name);
                }
            }

            const childNode = new ReportTreeNode(childNodeValue, this, (child as ReportGroup).reports || []);

            this.children.push(childNode);
        }
    }
}
