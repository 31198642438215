import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/core/services/api.service';
import { User } from '@app/core/auth/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UsersApiService {
    constructor(
    private apiService: ApiService
    ) {}

    public getMe(): Observable<User> {
        return this.apiService.get<User>('user/me');
    }
}
