import { Injectable } from '@angular/core';
import { IFundFamily } from '@common/models/IFundFamily';
import { ApiService } from '@app/core/services/api.service';
import { Observable, of } from 'rxjs';
import { ISystemProperty, SystemPropertyValue } from '@common/models/ISystemProperty';

@Injectable({
    providedIn: 'root'
})
export class SystemPropertiesService {
    constructor(private apiService: ApiService) {

    }

    public getAll(): Observable<IFundFamily[]> {
        return this.apiService.get('SystemProperty');
    }

    public getByKey<T extends SystemPropertyValue>(key: string): Observable<T> {
        if (localStorage['systemPropertyCache'] !==null) {
            const systemData = JSON.parse(localStorage['systemPropertyCache'] || '[]');
            return of(systemData.filter(prop => prop.key === key)[0]?.value)
        }
    }
}
