import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../../environments/environment';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isMenuOpened = false;

    constructor(private router: Router) {
    }

    public ngOnInit(): void {
        this.router.events
            .pipe(untilDestroyed(this))
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.isMenuOpened = false;
                }
            });
    }

    public environment(): any {
        return environment;
    }

    public onMenuToggle(): void {
        this.isMenuOpened = !this.isMenuOpened;
    }

    public onMenuClose(): void {
        this.isMenuOpened = false;
    }
}
