import { Injectable } from '@angular/core';

import { ReportGroup } from '@app/shared/features/reports/models/report-group.model';
import { Report } from '../models/report.model';
import { ReportsItem } from '../types/reports-item.type';

@Injectable({
    providedIn: 'root'
})
export class ReportsHelper {
    public getFirstReport(reports: ReportsItem[]): Report {
        let firstReport: Report;

        this.traverseReports(reports, (report: Report) => {
            if (!firstReport) {
                firstReport = report as Report;
            }
        });

        return firstReport;
    }

    public findReport(reportData: any, reports: ReportsItem[]): Report {
        let selectedReport: Report;

        // if the report data has a :page string, it must be a multi-page report,
        // so we need to find the report entry based on its title/page rather than the name
        //* *find it based on title
        if (reportData.page) {
            this.traverseReports(reports, (report: Report) => {
                if (report.title === reportData.page) {
                    selectedReport = report as Report;
                }
            });
            //* *find it based on name
        } else {
            this.traverseReports(reports, (report: Report) => {
                if (report.name === reportData.report) {
                    selectedReport = report as Report;
                }
            });
        }

        return selectedReport;
    }

    public static isReportGroup(reportsItem: ReportsItem): boolean {
        return (reportsItem as ReportGroup).reports && !(reportsItem as Report).name;
    }

    private traverseReports(reports: ReportsItem[], callback): void {
        (function recurseFindReport(reportsItems: ReportsItem[]) {
            reportsItems.forEach((reportsItem: ReportsItem) => {
                if (!ReportsHelper.isReportGroup(reportsItem)) {
                    callback(reportsItem as Report);
                } else {
                    recurseFindReport((reportsItem as ReportGroup).reports);
                }
            });
        }(reports));
    }
}
