import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
    
@Pipe({ name: 'unit' })
export class UnitPipe implements PipeTransform {

    public transform(value: string, unit: string): string {
        switch (unit) {
            case 'USD':
                return `$${this.addNumberCommas(value)}`;
            case 'ratio':
                return `${value}x`;
            case 'percent':
                return `${value}%`;
            case 'Boepd':
                return `${this.addNumberCommas(value)} Boepd`;
            case 'Mboe':
                return `${this.addNumberCommas(value)} Mboe`;
            case 'date':
                return this.formatDate(value);
            default:
                return value;
        }
    }

    private formatDate(date: string): string {
        return dayjs(date).format('M/D/YYYY');
    }

    private addNumberCommas(value: string): string {
        if (isNaN(parseInt(value, 10))) { return value; }

        return parseInt(value, 10).toLocaleString();
    }
}
