import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { IFundFamily } from '@common/models/IFundFamily';
import { Observable, map, of, shareReplay } from 'rxjs';
import { ApiService } from './core/services/api.service';
import { IUser } from '@common/models/IUser';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {
  constructor(private apiService: ApiService){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (localStorage['userCache'] ===null || localStorage['userCache'] ===undefined)  {
      return this.apiService.get(`User`)
      .pipe(
          map((res: IUser[]) => localStorage['userCache'] = JSON.stringify(res)),
          shareReplay(1),
      );
    }
  }
}
