import { Injectable } from '@angular/core';
import { MsalService } from '@app/core/auth/msal/msal.service';
import { SilentRequest, AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MsGraphAccessToken {
    constructor(
    private authService: MsalService
    ) {}

    public getMsGraphAccessToken(request: SilentRequest): Observable<AuthenticationResult> {
        return this.authService.acquireTokenSilent(request);
    }
}
