/**
 * Common status codes for HTTP responses.
 */
export enum HttpStatusCodes {
  Ok = 200,
  Created = 201,
  Accepted = 202,
  ClientError = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  ServerError = 500
}
