import { Directive, Injector, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CurrentUserStateService } from '@app/core/auth/services/current-user-state.service';

@UntilDestroy()
@Directive()
export abstract class BaseToggleDirective implements OnInit {
    private templateRef: TemplateRef<any>;
    private viewContainer: ViewContainerRef;
    protected currentUserStateService: CurrentUserStateService;

    constructor(protected readonly injector: Injector) {
        this.templateRef = this.injector.get(TemplateRef);
        this.viewContainer = this.injector.get(ViewContainerRef);
        this.currentUserStateService = this.injector.get(CurrentUserStateService);
    }

    public ngOnInit(): void {
        this.initCurrentUserObserver();
    }

  protected abstract isElementNeeded(): boolean;

  private processElement(): void {
      if (this.isElementNeeded()) {
          this.createElement();
      } else {
          this.destroyElement();
      }
  }

  private initCurrentUserObserver(): void {
      this.currentUserStateService.value$
          .pipe(untilDestroyed(this))
          .subscribe(
              () => {
                  this.processElement();
              }
          );
  }

  private createElement(): void {
      this.destroyElement();
      this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private destroyElement(): void {
      this.viewContainer.clear();
  }
}
