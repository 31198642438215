import { Directive, Input, OnInit } from '@angular/core';

import { UserRole } from '@common/enums/UserRole';
import { BaseToggleDirective } from './base-toggle.directive';

@Directive({
    selector: '[availableFor]'
})
export class AvailableForDirective extends BaseToggleDirective implements OnInit {
  @Input('availableFor')
    public readonly targetRoles: UserRole[];

  protected isElementNeeded(): boolean {
      return this.targetRoles ? this.currentUserStateService.hasOneOfRoles(this.targetRoles) : true;
  }
}
