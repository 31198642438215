import { ReportsItem } from '@app/shared/features/reports/types/reports-item.type';
import { ReportTreeNode } from './report-tree-node.model';

export class ReportTree {
    private root: ReportTreeNode;

    constructor(reports: ReportsItem[]) {
        this.root = new ReportTreeNode(null, null, reports);
    }

    public getRoot(): ReportTreeNode {
        return this.root;
    }
}
