import { OverrideFunction } from './override-function.model';

export const override: OverrideFunction = (): void => {
    const previousValue = window.encodeURIComponent;

    window.encodeURIComponent = (str: string) => (
        previousValue(str).replace(/[!'()*]/g, (char) => (
            `%${char.charCodeAt(0).toString(16)}`
        ))
    );
};
