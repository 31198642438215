import { Injectable } from '@angular/core';

import { OverridesService } from './overrides/overrides.service';

@Injectable()
export class AppConfig {
    constructor(private overridesService: OverridesService) { }

    public configureApp(): Promise<any> {
        this.overrideGlobalFunctions();

        return Promise.resolve(true);
    }

    private overrideGlobalFunctions(): void {
        this.overridesService.overrideGlobalFunctions();
    }
}
