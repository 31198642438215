<div class="page-header">
	<h2 class="page-header__title">
		{{title}}
	</h2>
	<p class="page-header__date">
		Due date: {{date}}
	</p>
	<button *ngIf="isCloseBtnNeeded" class="btn page-header__close" (click)="closeBtnHandler()">
		<img class="img-fluid" src="../../../assets/images/cancel.svg"/>
	</button>
</div>