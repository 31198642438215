import { Pipe, PipeTransform } from '@angular/core';

export interface KeyValue<K, V> {
  key: K;
  value: V;
}

@Pipe({ name: 'keyvalue' })
export class KeyValuePipe implements PipeTransform {
    public transform<K, V>(input: any): KeyValue<K, V>[] {
        const keyValues = [];

        for (const key in input) {
            keyValues.push({ key, value: input[key] });
        }

        return keyValues;
    }
}
