<div>
  <label
    [for]="'file-upload-' + formName"
    class="p-button-secondary"
    style="margin-top: 8px; margin-bottom: 8px; max-width: 10px"
  >
    <i class="pi pi-upload btn-hover"></i
  ></label>
  <input
    type="file"
    [id]="'file-upload-' + formName"
    style="visibility: hidden; max-width: 10px"
    (change)="onBasicUpload($event)"
    [accept]="attachmentTypes"
  />
</div>
