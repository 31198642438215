import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MsalService } from '@app/core/auth/msal/msal.service';
import { SilentRequest } from '@azure/msal-browser';
import { HttpStatusCodes } from '@common/enums/HttpStatusCodes';
import { environment } from 'src/environments/environment';
import { MsGraphAccessToken } from '@app/services/ms-graph-token.service';

@UntilDestroy()
@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {
    public profilePicExists: boolean;
    public profilePicDoesNotExist: boolean;
    private request: SilentRequest = {
        authority: environment.authority,
        forceRefresh: true,
        scopes: [environment.userScope],
        account: this.authService.getAllAccounts()[0]
    };

    constructor(
    private authService: MsalService,
    private msGraphAccessTokenService: MsGraphAccessToken
    ) {
        this.profilePicExists = false;
        this.profilePicDoesNotExist = false;
    }

    public ngOnInit(): void {
        this.getProfilePic();
    }

    private getProfilePic(): void {
        this.msGraphAccessTokenService.getMsGraphAccessToken(this.request)
            .subscribe((token) => {
                const { accessToken } = token;
                this.callGraph(environment.photoEndpoint, accessToken);
            });
    }

    // eslint-disable-next-line max-lines-per-function
    private callGraph(endpoint: string, token: string): void {
        const bearer = `Bearer ${token}`;
        const headers = new Headers();

        headers.append('Authorization', bearer);

        const options = {
            method: 'GET',
            headers
        };

        fetch(endpoint, options)
            .then((response) => {
                if (response.status !== HttpStatusCodes.Ok) {
                    this.profilePicExists = false;
                    this.profilePicDoesNotExist = true;
                    throw new Error(`Status Code: ${response.status}, could not retrieve user profile image`);
                } else {
                    this.profilePicExists = true;

                    return response.arrayBuffer();
                }
            })
            .then((data) => {
                this.getGraphResults(data);
            })
        // eslint-disable-next-line no-console
            .catch((err) => console.error(err));
    }

    private getGraphResults(data: ArrayBuffer): void {
        const blob = new Blob([data], { type: 'image/jpeg' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl: string = urlCreator.createObjectURL(blob);
        const img: HTMLImageElement = document.querySelector('img.user-profile-pic');
        img.src = imageUrl;
    }
}
