import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserProfileService } from '@app/modules/user-profile/services/user-profile.service';
import { IUser } from '@common/models/IUser';

@Component({
  selector: 'app-user-chip',
  templateUrl: './user-chip.component.html',
  styleUrls: ['./user-chip.component.scss']
})
export class UserChipComponent implements OnInit {
  @Input() userEmail: string;
  imagePath : SafeResourceUrl ;
  toReturnImage : string ;
  userName : string= '';
  constructor(private userService: UserProfileService, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.imagePath = 'assets/images/users/placeholder.jpg'
    this.userName = this.userEmail;
    this.getUser();
  }
  async getUser()
  {
    const email = this.userEmail ;
    this.userService.getUserByEmail(email).subscribe((res: IUser) => {
      if(res){
        this.toReturnImage = res.photo;
        this.userName = res['name'];
        if(this.toReturnImage)
        {
          this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
          + this.toReturnImage);
        }   
      }
          
      
    });
  }

}
