<div class="grid">
    <div class="flex col-2 align-items-center justify-content-center">
        <img alt="Company Logo" *ngIf="company && ((company.image ?? '') !== '')" src="{{imageLocation}}/{{ company?.mnemonic }}/{{company?.image}}" />
    </div>
    <div class="col-1"></div>
    <div class="col-2">
        <div class="property-name">Name</div>
        <div class="property-value">{{ company.name }}</div> 
        <div class="property-name">LLC Legal Name</div>
        <div class="property-value">{{ company.legalName }}</div> 
 
    </div>
    <div class="col-3">
        <div class="property-name">Stage</div>
        <div class="property-value">{{ company.stage }}</div> 
        <div class="property-name">Quantum Capital Member</div>
        <div class="property-value">{{ company.quantumCapitalMember }}</div> 

    </div>
    <div class="col-4">
        <div class="property-name"><span class="inline-block">Directors</span>
            <span class="ml-3" ><i [pTooltip]="infoMsg" class="pi pi-pencil cursor-pointer" (click)="editList()"></i></span>
        </div>
        <div class="chip-section">
            <div *ngFor="let member of company?.team?.boardMembers">
                <app-user-chip *ngIf="member" [userEmail]="member"></app-user-chip>
            </div>
        </div>
        <div class="property-name">Execution Team</div>
        <div class="chip-section">
            <div *ngFor="let member of company?.team?.executionTeam">
                <app-user-chip *ngIf="member" [userEmail]="member"></app-user-chip>
            </div>
        </div>
        <div class="property-name">Oversight Partner</div>
        <div class="chip-section">
            <div *ngFor="let member of company?.team?.oversightPartners">
                <app-user-chip *ngIf="member" [userEmail]="member"></app-user-chip>
            </div>
        </div>
        
    </div>
</div>
