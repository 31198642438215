import { Injectable } from '@angular/core';
import { defer, finalize, NEVER, share, BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    visibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    resetSpinnerStatus() {
        this.visibility.next(false);
    }

    spinnerShow() {
        this.visibility.next(true);
    }

    spinnerHide() {
        this.visibility.next(false);
    }

    public readonly spinner$ = defer(() => {
        this.spinnerShow();
        return NEVER.pipe(
            finalize(() => {
                this.spinnerHide();
            })
        );
    }).pipe(share());
}


