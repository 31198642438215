import { Directive, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Directive({
    selector: '[formControl][disableIf]'
})
export class DisableIfFormControlDirective {
    @Input() formControl: FormControl;

    constructor() { }

    get disableIf(): boolean {
        return !!this.formControl && this.formControl.disabled;
    }

    @Input() set disableIf(s: boolean) {
        if (!this.formControl) return;
        else if (s) this.formControl.disable();
        else this.formControl.enable();
    }
}