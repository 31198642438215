import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SystemPropertiesService } from '@app/core/services/system-properties.service';
import { IPortfolioCompany } from '@common/models/IPortfolioCompany';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyHeaderComponent implements OnInit  {

  @Input () company: IPortfolioCompany;
  infoMsg: string ='Click to edit PC Master list, contacts';
  listName : string;
  url: string;
  constructor(private systemPropertiesService: SystemPropertiesService,) { }

  ngOnInit(): void {
    this.systemPropertiesService.getByKey<string>('default.portfolioCompanyMasterList').subscribe((res) => {
      this.listName = res;
    });
  }

  get imageLocation(): string {
    return environment.companyImageLocation;
  }

  editList(){
    // Calculate url at last minute, so all properties are available
    this.url = `https://quantumep.sharepoint.com/sites/MasterDataManagement/Lists/${this.listName}/AllItems.aspx?viewid=50de5353%2D5a0c%2D4188%2Da0a6%2De3c731a4c357&useFiltersInViewXml=1&FilterField1=Mnemonic&FilterValue1=${this.company.mnemonic}&FilterType1=Text&FilterOp1=In`;
    window.open(this.url);
  }

}
