<div class="dropdown-navigation">
  <button class="dropdown-navigation__arrow" (click)="navigateToPrevious()">
    <app-arrow class="white smaller"></app-arrow>
  </button>
  <select
    class="dropdown-navigation__selector form-control"
    (change)="navigateTo($event.target.value)"
  >
    <option
      *ngFor="let item of items"
      [value]="item.id"
      [selected]="item.id === currentItemId"
    >
      {{ item.name }}
    </option>
  </select>
  <button class="dropdown-navigation__arrow" (click)="navigateToNext()">
    <app-arrow class="white smaller right"></app-arrow>
  </button>
</div>
