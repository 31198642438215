import { Injectable } from '@angular/core';

import { OverrideFunction } from './override-function.model';
import { override as overrideEncodeURIComponent } from './encodeURIComponent.override';

@Injectable({
    providedIn: 'root'
})
export class OverridesService {
    private overrides: OverrideFunction[] = [overrideEncodeURIComponent];

    public overrideGlobalFunctions(): void {
        this.overrides.forEach((overrideFn) => {
            overrideFn();
        });
    }
}
