<ul
  *ngIf="reportsTree"
  class="nav">
  <ng-template
    [ngTemplateOutlet]="nodeTemplateRef"
    [ngTemplateOutletContext]="{ $implicit: reportsTree.getRoot() }">
  </ng-template>
</ul>

<ng-template #nodeTemplateRef let-node>
 
	<li
    class="nav__item"
    [ngClass]="isSeparated(node.value)? 'separated' : ''">

    <button
      *ngIf="node.value?.title"
      class="p-button"
      [class.active]="isActive(node.value)" 
      [ngClass]="isReportNode(node.value) ? 'nav__item-btn' : 'nav__item-title'" 
      (click)="onSelect(node.value)">
      {{node.value.title}}
    </button>
 
		<div *ngIf="node.children?.length" class="node__children">
			<ng-template
				ngFor
				[ngForOf]="node.children"
				[ngForTemplate]="nodeTemplateRef">
			</ng-template>
		</div>
  
	</li>
 
</ng-template>


