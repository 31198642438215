import {
    ModuleWithProviders,
    NgModule,
    Optional,
    SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { AppScopeSet, AuthConfig, MSALInstanceFactory, MSALInterceptorConfigFactory } from './msal-configurator';
import { MsalService } from './msal/msal.service';
import { MsalGuard } from './msal/msal.guard';
import { MsalBroadcastService } from './msal/msal.broadcast.service';
import { MsalInterceptor } from './msal/msal.interceptor';

export interface AuthModuleConfig {
  appScopeSets: AppScopeSet[];
  authConfig: AuthConfig;
}

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [],
    providers: [MsalService, MsalGuard, MsalBroadcastService]
})
export class AuthModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error(
                'AuthModule is already loaded. Import it in the AppModule only'
            );
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true
                },
                {
                    provide: MSAL_INSTANCE,
                    useFactory: () =>
                        MSALInstanceFactory({ auth: config.authConfig })
                },
                {
                    provide: MSAL_GUARD_CONFIG,
                    useValue: {
                        interactionType: InteractionType.Redirect
                    } as MsalGuardConfiguration
                },
                {
                    provide: MSAL_INTERCEPTOR_CONFIG,
                    useFactory: () =>
                        MSALInterceptorConfigFactory({ ...config, interactionType: InteractionType.Redirect })
                },
                MsalService,
                MsalGuard,
                MsalBroadcastService
            ]
        };
    }
}
