<div *ngIf="profilePicExists">
  <div class="profile-pic-container">
    <img class="user-profile-pic" alt="user"/>
  </div>
</div>

<div *ngIf="profilePicDoesNotExist">
  <i-bs
    [width]="'32px'"
    [height]="'32px'"
    [name]="'person-circle'">
  </i-bs>
</div>
