<div class="user-wrap">

    <div class="user-dropdown">
        <a [href]="environment().helpUrl" target="_blank"><i class="pi pi-question-circle mr-4 cursor" style="font-size: 2rem"></i></a>
        <button type="button" class="p-button user-button" (click)="menu.toggle($event)">   

        <app-profile-picture></app-profile-picture>

        {{ me }}
      </button>

    </div>

    <p-menu class="custom-menu" #menu [popup]="true" [model]="userMenuItems" >
    </p-menu>

</div>