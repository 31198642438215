import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { SharedModule } from '@app/shared/shared.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
    imports: [
        SharedModule,
        HttpClientModule,
        AuthModule.forRoot(environment.auth)
    ]
})
export class CoreModule { }
