import {
    Configuration,
    InteractionType,
    IPublicClientApplication,
    PublicClientApplication
} from '@azure/msal-browser';

import { MsalInterceptorConfig } from './msal/msal.interceptor.config';

export interface AuthConfig {
  clientId: string;
  authority: string;
  redirectUri: string;
}

export interface AppScopeSet {
  resource: string;
  scopes: string[];
}

export function MSALInstanceFactory(config: Configuration): IPublicClientApplication {
    return new PublicClientApplication(config);
}

export function MSALInterceptorConfigFactory({
    appScopeSets,
    interactionType = InteractionType.Popup
}: {
  appScopeSets: AppScopeSet[];
  interactionType?: InteractionType.Popup | InteractionType.Redirect;
}): MsalInterceptorConfig {
    const protectedResourceMap = new Map<string, Array<string>>();
    appScopeSets.forEach((scopesSet) => {
        protectedResourceMap.set(scopesSet.resource, scopesSet.scopes);
    });

    return {
        interactionType,
        protectedResourceMap
    };
}
