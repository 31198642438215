import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { IAttachment } from '@common/models/IAttachment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() attachmentData: IAttachment
  @Output() fileUploadData = new EventEmitter<any>()
  formName: string
  attachmentTypes: string = ""

  constructor() { 
    this.formName = uuidv4();
  }

  ngOnInit(): void {
    const { typeDefinition } = this.attachmentData
    if (typeDefinition.allowableFileTypes) {
      for (const attachment of typeDefinition.allowableFileTypes) {
        this.attachmentTypes += '.' + attachment + ','
      }
    } else {
      this.attachmentTypes = ""
    }
  }


  onBasicUpload(event) {
    const files = event.target.files
    this.fileUploadData.emit(files);
  }

}
