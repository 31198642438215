import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPowerBiReport } from '../models/power-bi-report.model';
import { ApiService } from '../../../../core/services/api.service';
import { Report } from '@app/shared/features/reports/models/report.model';

@Injectable({
    providedIn: 'root'
})
export class PowerBiApiService {
    private resourceUrl = 'PowerBi/reports';

    constructor(private apiService: ApiService) { }

    public get(stage: string, report: Report): Observable<IPowerBiReport> {
        return this.apiService.get<IPowerBiReport>(`${this.resourceUrl}/${stage}/${report.name}`);
    }
}
