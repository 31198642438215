import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[scrollTo]'
})
export class ScrollToDirective {
  @Input()
    public set scrollTrigger(value: boolean) {
        if (value) {
            this.scrollToElement();
        }
    }

  constructor(private element: ElementRef) {}

  private scrollToElement(): void {
      const { nativeElement: element } = this.element;
      const offset = 60;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - offset;

      if (this.isInViewport(element)) {
          return;
      }

      window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
      });
  }

  private isInViewport(element): boolean {
      const rect = element.getBoundingClientRect();

      return (
          rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
  }
}
